@import "../../base/layout";

.cta_banner {
    background: var(--primary-color-green);
    padding-top: 54px;
    // padding-bottom: 210px;
    padding-bottom: 40px;
    text-align: center;
    --max-width:508px;
    // &:after{
    // content: "";
    // width: 100%;
    // display: block;
    background: url("../../../public/static//images/home/doodle-background.svg") repeat center, var(--primary-color-green);
    background-size: cover;
    // background-size: cover;
    // }
    ._container {
        position: relative;
    }
    ._title {
        margin-bottom: 10px;
    }
    ._subtitle {
        font-size: 14px;
        letter-spacing: 0.23px;
        line-height: 1.43;
        font-family: var(--font-medium);
        margin-bottom: 48px;
    }
    ._title , ._subtitle {
        color:var(--font-color-light);
    }
    ._cta {
        padding: 0 8px;
        max-width: var(--max-width);
        margin-left:auto;
        margin-right: auto;
        .mobile_location
            {
                display:flex;
                text-align:left;
                position: relative;
                margin-bottom: 10px;
                width: max-content;
                &::before{
                    font-family: "Icon";
                    position: absolute;
                    content: "";
                    font-size: 0.7rem;
                    color: white;
                    opacity: 1;
                    transform: translateY(-50%);
                    font-weight: bold;
                    margin-top: 13px;
                    margin-left: 9px;
                    right: 0px;
                    margin-right: -20px;
                }

                ._city{
                    max-width:110px;
                    margin: auto 0;
                    text-align: initial;
                    font-family: var(--font-semi-bold);
                    font-size: 14px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    cursor: pointer;
                    line-height: 20px;
                    margin:auto 0;
                    color:white;
                    font-family: var(--font-semi-bold)
                    }
                .location_image{
                    margin:  auto 5px;
                }
            }
            .location_popup{
                position: absolute;
                margin-top: 69px;
                background: rgba(62, 64, 84, 1);
                z-index: 1;
                min-width: 85%;
                max-width: 85%;
                padding-bottom: 20px; 
                box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
                border-radius: 4px;
                left: 0;
                right: 0;
                margin-left: auto;
                margin-right: auto;
                .location_text{
                    display: flex;
                    
                    p{
                        width: 270px;
                        padding: 20px 10px 10px 10px;
                        font-size: 14px;
                        color: white;
                        font-family: var(--font-semi-bold);
                        text-align: initial;
                        line-height: 20px;
    
                    }

                    .location_image{
                        margin:auto 0px auto 22px;
                    }
                }
                .location_options{
                    padding-left: 25px;
                    padding-top: 20px;
                    display: flex;
                    justify-content: space-around;
                    .option_auto
                    {
                        font-family: var(--font-semi-bold);
                        color: var(--primary-color-orange);
                        font-size: 16px;

                    }
                    .option_manual
                    {
                        font-family: var(--font-semi-bold);
                        color: white;
                        font-size: 16px;

                    }

                }
                
            }
            .blinker{
                position: absolute;
                top: 56px;
                left: 107px;
                animation: blinker 2s linear infinite;
                width: 25px;
                height: 25px;
                display: inline-block;
                border: 1px solid #3E4054;
                background-color: #3E4054;
                border-radius: 100%;
                z-index: 1;
                &::after{
                    border-left: 3px dotted black;
                    content: "";
                    width: 100px;
                    height: 55px;
                    left: 43%;
                    position: absolute;
                    top: 24px;
                  }
            }
            @keyframes blinker {
                from, to {
                opacity: 0.5;
                }
                50% {
                opacity: 1;
                }
            }
    }
    ._dropdown {
        
        border-radius: 40px;
        box-shadow: 0 0 10px 0 #00000022;
        ._dropdown_label {
            color:rgba(87,95,101,0.38);
        }
        &[data-is-selected="true"] {
           ._dropdown_label {
               color:#000;
           }
        }
        &[data-home="true"] {
            padding-left: 20px;
        }
    }
    @media (min-width: map-get($grid-breakpoints,lg)) {
        ._cta{
            ._city{
                &::before{
                    margin-left: 8% !important;
                }
            }
        }
    }

    @media (min-width: 1440px) {
        ._cta{
            ._city{
                &::before{
                    margin-left: 9% !important;
                }
            }
        }
    }

    @media (min-width: 744px) and (max-width: 767px)
    {
        padding-top: 87px;
        padding-bottom: 80px;
        ._dropdown {
            height: 56px;
            box-shadow: none;
            border: none;
            &[data-home="true"]
            {
                padding-left: 0px;
            }
            border-radius: 40px;
        }
        h1 {
            font-size: 32px !important;
        }
        ._subtitle {
            font-size: 16px;
            line-height: 1.69;
            letter-spacing: 0.27px;
            margin-bottom:40px;
            margin-left:auto;
            margin-right: auto;
            
        }
        ._subtitle {
            max-width: var(--max-width);
        }
        ._cta {
            max-width: 700px;
            padding:0;
            display: flex;
            background-color: white;
            border-radius: 40px;
            .triangle{
                position: absolute;
                border-bottom: 11px solid #fff;
                border-right: 11px solid transparent;
                border-left: 11px solid transparent;
                z-index: 1;
                display: inline-block;
                margin-top: 58px;
                margin-left: 25px;
                z-index: 2;
            }
            .or{
                display: flex;
                flex-direction: row;
                font-size: 14px;
                font-family: var(--font-regular);
              }
              .or:before, .or:after{
                content: "";
                flex: 1 1;
                border-bottom: 0.5px solid #565F65;
                margin: auto 20px;
                opacity: 0.2;
              }
              .or:before {
                margin-right: 16px
              }
              .or:after {
                margin-left: 16px
              }
            .location{
                position: absolute;
                margin-top: 69px;
                background: white;
                height: auto;
                z-index: 1;
                min-width: 320px;
                max-width: 320px;
                padding-bottom: 5px; 
                box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
                border-radius: 4px;

                .request{
                    display: flex;
                    justify-content: center;
                    div{
                        margin: auto 0;
                    }
                    p{
                        width: 270px;
                        padding: 20px 10px 10px 10px;
                        font-size: 14px;
                        color: #3D3D3D;
                        font-family: var(--font-semi-bold);
                        text-align: initial;
                        line-height: 20px;
                        
                    }
                }
                .detect_location{
                    display: flex;
                    justify-content: center;
                    div{
                        margin: auto 0;
                    }
                    p{
                        padding: 25px 25px 25px 15px;
                        font-size: 15px;
                        color: #FDA000;
                        font-family: var(--font-semi-bold);
                        cursor: pointer;
                    }
                }
            }
            

            .error_block
            {
                background-color: rgba(208, 2, 27, 0.1);
                display: flex;
                justify-content: center;
                margin-bottom: 10px;
                .geo_error{
                    font-size: 12px;
                    line-height: 14px;
                    color: #D0021B;
                    text-align: initial;
                }
                p{
                    width: 250px;
                    line-height: 14px;
                    margin-left: 10px;
                    padding: 15px 0;
                    cursor: pointer;
                }
                .err_img{
                    margin: auto 0;
                }
            }
            ._city{
                width:170px;
                margin: auto 0;
                padding-right: 30px;
                text-align: initial;
                font-family: var(--font-semi-bold);
                font-size: 14px;
                line-height: 20px;
                text-overflow: ellipsis;
                overflow: hidden;
                cursor: pointer;
                padding-top: 5px;
                padding-bottom: 5px;
                &:before{
                    font-family: "Icon";
                    position: absolute;
                    content: "";
                    font-size: 1rem;
                    color: #535353;
                    opacity: 1;
                    transform: translateY(-50%);
                    font-weight: bold;
                    margin-top: 11px;
                    margin-left: 14%;
                }
                border-right: 1px solid var(--primary-color-green);
            }
        }
    }

    @media (min-width: map-get($grid-breakpoints,sm)) {
        padding-top: 87px;
        // padding-bottom: 196px;
        padding-bottom: 80px;
        ._dropdown {
            height: 56px;
            box-shadow: none;
            border: none;
            &[data-home="true"]
            {
                padding-left: 0px;
            }
            border-radius: 40px;
        }
        ._title {
            margin-bottom:12px;
            font-size: 42px;
        }
        ._subtitle {
            font-size: 16px;
            line-height: 1.69;
            letter-spacing: 0.27px;
            margin-bottom:40px;
            margin-left:auto;
            margin-right: auto;
            
        }
        ._subtitle {
            max-width: var(--max-width);
        }
        ._cta {
            max-width: 700px;
            padding:0;
            display: flex;
            background-color: white;
            border-radius: 40px;
            .triangle{
                position: absolute;
                border-bottom: 11px solid #fff;
                border-right: 11px solid transparent;
                border-left: 11px solid transparent;
                z-index: 1;
                display: inline-block;
                margin-top: 58px;
                margin-left: 25px;
                z-index: 2;
            }
            .or{
                display: flex;
                flex-direction: row;
                font-size: 14px;
                font-family: var(--font-regular);
              }
              .or:before, .or:after{
                content: "";
                flex: 1 1;
                border-bottom: 0.5px solid #565F65;
                margin: auto 20px;
                opacity: 0.2;
              }
              .or:before {
                margin-right: 16px
              }
              .or:after {
                margin-left: 16px
              }
            .location{
                position: absolute;
                margin-top: 69px;
                background: white;
                height: auto;
                z-index: 1;
                min-width: 320px;
                max-width: 320px;
                padding-bottom: 5px; 
                box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
                border-radius: 4px;

                .request{
                    display: flex;
                    justify-content: center;
                    div{
                        margin: auto 0;
                    }
                    p{
                        width: 270px;
                        padding: 20px 10px 10px 10px;
                        font-size: 14px;
                        color: #3D3D3D;
                        font-family: var(--font-semi-bold);
                        text-align: initial;
                        line-height: 20px;
                        
                    }
                }
                .detect_location{
                    display: flex;
                    justify-content: center;
                    div{
                        margin: auto 0;
                    }
                    p{
                        padding: 25px 25px 25px 15px;
                        font-size: 15px;
                        color: #FDA000;
                        font-family: var(--font-semi-bold);
                        cursor: pointer;
                    }
                }
            }
            

            .error_block
            {
                background-color: rgba(208, 2, 27, 0.1);
                display: flex;
                justify-content: center;
                margin-bottom: 10px;
                .geo_error{
                    font-size: 12px;
                    line-height: 14px;
                    color: #D0021B;
                    text-align: initial;
                }
                p{
                    width: 250px;
                    line-height: 14px;
                    margin-left: 10px;
                    padding: 15px 0;
                    cursor: pointer;
                }
                .err_img{
                    margin: auto 0;
                }
            }
            ._city{
                width:170px;
                margin: auto 0;
                padding-right: 30px;
                text-align: initial;
                font-family: var(--font-semi-bold);
                font-size: 14px;
                line-height: 20px;
                text-overflow: ellipsis;
                overflow: hidden;
                cursor: pointer;
                padding-top: 5px;
                padding-bottom: 5px;
                &:before{
                    font-family: "Icon";
                    position: absolute;
                    content: "";
                    font-size: 1rem;
                    color: #535353;
                    opacity: 1;
                    transform: translateY(-50%);
                    font-weight: bold;
                    margin-top: 11px;
                    margin-left: 14%;
                }
                border-right: 1px solid var(--primary-color-green);
            }
        }
    }
}
@media (min-width: map-get($grid-breakpoints,lg-legacy)) {
    ._container {
        position: relative;
        // &:before , &:after  {
        //     position: absolute;
        //     display: inline-block;
        //     content:"";
        //     width:300px;
        //     height:285px;
        //     background: red;
        //     bottom:-60px;
        // }
        // &:before {
        //    left:0;
        //    background: url(../../../public/static/images/home/banner/banner-left.svg) no-repeat center;
        //    background-size: contain;
        // }
        // &:after {
        //     right:0;
        //     background: url(../../../public/static/images/home/banner/banner-right.svg) no-repeat center;
        //     background-size:contain;
        // }
    }
}

@media (min-width: map-get($grid-breakpoints,xl)) {
    ._container {
       
        &:before , &:after  {
           
            width:380px;
            height:348px;
            background-size:cover;
           
        }
        &:before {
           left:-110px;
          
        }
        &:after {
            right:-110px;
            
        }
    }
}