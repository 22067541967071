@import "../../base/layout";
@import "../../utilities/utilities";

.app_dropdown__labelname{
  display: block;
  margin-bottom: 5px;
  font-family: var(--font-regular);
  font-size: 14px;
  em {
    color:var(--font-color-error);
    font-family: var(--font-bold);
}
}

.removeInput{
  position: absolute;
  right: 12px;
  top: 37px;
  &::after{
    display: inline-block;
    font-family: "Icon";
    content: "";
    font-size: 6px;
    padding: 5px;
    border-radius: 50%;
    background-color: #565F65;
    color: #fff;
    font-weight: bolder;
    cursor: pointer;
  }
  @media (min-width: map-get($grid-breakpoints,md)) and (max-width: map-get($grid-breakpoints,lg)) {
    top: 80px;
  }
  @media (max-width: map-get($grid-breakpoints, sm)) {
    top: 38px;
    right: 25px !important;
  }
}

.app_dropdown {
  position: relative;
  width: 100%;
  height: 48px;
  border-radius: var(--border-radius-sm);
  border: solid 1px #c0c0c0;
  background-color: #ffffff;
  &[data-valid="false"] {
    border-color: var(--font-color-error);
  }
  &[data-generic="true"]:after {
    font-family: "Icon";
    position: absolute;
    content: "\e900";
    right: 10px;
    top: 50%;
    color: #535353;
    opacity: 1;
    transform: translateY(-50%);
    font-weight: bold;
    transition: transform 300ms ease-in;
    // padding-right: 15px;
  }
  &[data-home="true"] {
    padding-left: 41px;
  }
  &[data-home="true"]:before {
    position: absolute;
    content: "";
    font-size: 16px;
    color: #575f65;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    background-image: url('../../../public/static/images/home/search.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    width: 20px;
    height: 20px;

  }

  &.protection_plans__modal {
    width: 82px;
    height: 38px;
    font-size: 12px;
    margin-top:14px;
    .app_dropdown__label {
      padding: 12px;
    }
    .app_dropdown__menu {
      .dropdown__menu_item {
        font-size: 12px;
        padding: 12px;
      }
    }
    @media (min-width: map-get($grid-breakpoints,lg)) {
      width: 120px;
      font-size: 14px;
      .app_dropdown__menu {
        .dropdown__menu_item {
          font-size: 14px;
        }
      }
    }
  }
  @media (min-width: map-get($grid-breakpoints,lg)) {
    height: 54px;
  }
  &:focus {
    outline: 0;
  }
  & > .app_dropdown__label {
    display: block;
    font-size: 14px;
    padding: 17px 37px 17px 16px;
    font-family: var(--font-medium);
    text-align: left;
    color: #000;
    letter-spacing: 0.23px;
    line-height: 1;
    @extend %ellipsis;
    padding-right: 37px;

    @media (min-width: map-get($grid-breakpoints,lg)) {
      padding: 20px 43px 20px 16px;
      padding-right: 43px;
    }
  }
  // &.search > .search {
  //     display:none;
  // }
  &.search > .text {
    position: absolute;
    top: 0;
  }
  &.search > .text.filtered {
    display: none;
  }
  .app_dropdown__search {
    pointer-events: none;
    //user-select: none;
    width: 100%;
    padding: 16px 37px 16px 16px;
    border-radius: 4px;
    border: none;
    font-size: 14px;
    letter-spacing: 0.23px;
    line-height: 1;
    font-family: var(--font-medium);
    background: transparent;
    opacity: 0;
    z-index: -1;
    padding-right: 45px;
    @media (min-width: map-get($grid-breakpoints,lg)) {
      padding: 17px 30px 17px 16px;
      padding-right: 43px;
      transform: translateY(1px);
    }
    &:focus {
      outline: 0;
      border: none;
    }
    
  }
  .app_dropdown_hide{
    margin-top: 54px;
  }
  .app_input_pin{
    padding-left: 20px !important;
  }
  .app_dropdown__menu {
    position: absolute;
    width: calc(100% + 4px);
    bottom: -20px;
    left: -2px;
    opacity: 0;
    user-select: none;
    pointer-events: none;
    transform: translateY(100%);
    transition: all 200ms ease-in;
    background-color: #fff;
    border-radius: var(--border-radius-sm);
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.07);
    max-height: 212px;
    overflow-y: auto;
    z-index: -1;
    &::-webkit-scrollbar  {
      width:8px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1; 
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb  {
      background: #757575;
      border-radius:4px;
    }
    &[data-position="top"] {
      bottom:auto;
      top:-10px;
      transform: translateY(-100%);
    }
    .dropdown__menu_item {
      padding: 27px;
      text-align: left;
      cursor: pointer;

      span {
        pointer-events: none;
        font-family: var(--font-medium);
        font-size: 16px;
        color: #575f65;
        line-height: 1.3;
      }
      small {
        font-size: 12px;
        color: var(--primary-color-orange);
        font-family: var(--font-bold);
        padding-left: 10px;
      }
      &:hover, &:focus,
      &[data-selected="true"] {
        background-color: rgb(240, 240, 240);
      }
      &:focus {
        outline: none;
        background: var(--primary-color-green);
        span , small {
          color:#f6f6f6
        }
      }
      & + .dropdown__menu_item {
        border-top: 1px solid #cdd3d9;
      }
    }
  }

  &.summary_table__edit_fields {
    max-height: 30px;
    border-width: 2px;
    .app_dropdown__label {
      padding-top: 9px;
      padding-bottom: 9px;
      font-size: 12px;
    }
    .app_dropdown__menu {
      width: calc(100% + 100px);
      left: auto;
      right: 0;
      .dropdown__menu_item {
        padding-top: 9px;
        padding-bottom: 9px;
        span {
          font-size: 12px;
        }
      }
    }
    @media (min-width: map-get($grid-breakpoints,lg)) {
      max-height: 38px;
      width: 266px;
      right: auto;
      left: 0;
      .app_dropdown__label {
        padding-top: 12px;
        padding-bottom: 12px;
        font-size: 14px;
      }
      .app_dropdown__menu {
        width: 100%;
        .dropdown__menu_item {
          padding-top: 12px;
          padding-bottom: 12px;
          span {
            font-size: 14px;
          }
        }
        .app_dropdown__menu {
          width: 100%;
          .dropdown__menu_item {
            padding-top: 12px;
            padding-bottom: 12px;
          }
        }
      }
    }
  }
}
.dropdown__open:after , .dropdown__custom:focus:after {
  transform: translateY(-50%) rotate(-180deg) !important;
}

.dropdown__custom:focus .app_dropdown__menu, .dropdown__custom:focus-within .app_dropdown__menu , .dropdown__open .app_dropdown__menu {
  opacity: 1;
  bottom: -8px;
  pointer-events: all;
  z-index:1;
}

.dropdown__open[data-search="true"] .app_dropdown__label , .dropdown__custom[data-search="true"]:focus .app_dropdown__label , .dropdown__custom[data-search="true"]:focus-within .app_dropdown__label {
  display: none;
}

.dropdown__open[data-search="true"] .app_dropdown__search , .dropdown__custom[data-search="true"]:focus .app_dropdown__search , .dropdown__custom[data-search="true"]:focus-within .app_dropdown__search {
  display: block;
  pointer-events: all;
  opacity: 1;
  z-index: 1;
}

.dropdown__no_result {
  display: block;
  padding: 16px 30px 16px 16px;
  font-size: 20px;
  font-family: var(--font-semi-bold);
  text-align: center;
}

@media (max-width: map-get($grid-breakpoints,sm)) {
  .app_dropdown__mobile {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    //padding:24px;
    z-index: -2;
    user-select: none;
    opacity: 0;
    pointer-events: none;
    background: var(--theme-color-white);
    transform: translateY(20px);
    transition: opacity 150ms cubic-bezier(0.35, 0.96, 0, 1.21), transform 150ms cubic-bezier(0.35, 0.96, 0, 1.21) 20ms;
    &[data-is-open="true"] , .app_dropdown__mobile:focus , .app_dropdown__mobile:focus-within {
      z-index: 1000;
      pointer-events: all;
      opacity: 1;
      transform: translateY(0);
    }
    ul {
      max-height: calc(100% - 84px);
      overflow-y: auto;
      padding-left: 24px;
      padding-right: 19px;
      margin-top: 45px;
    }
    .dropdown__menu_item {
      padding-top: 24px;
      padding-bottom: 22px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        font-size: 14px;
        line-height: 1.71;
        color: #575f65;
        font-family: var(--font-medium);
        letter-spacing: 0.23px;
      }
      small {
        font-size: 12px;
        color: var(--primary-color-orange);
        font-family: var(--font-bold);
        padding-left: 10px;
      }
      small,
      span {
        pointer-events: none;
      }
      & + .dropdown__menu_item {
        border-top: 1px solid #cdd3d9;
      }
    }
    .device_type{
      text-align: right;
    }
    .app_dropdown__mobile_top {
      position: relative;
      display: flex;
      align-items: center;
      height: 90px;
      margin: 14px auto;
      margin-bottom: 20px;
      width: auto;
      padding-left: 16px;
      padding-right: 16px;
      a {
        display: block;
        width: 20px;
        height: 20px;
        transform: rotate(180deg);
        text-decoration: none;
        margin: 21px auto auto auto;

        &:before {
          display: inline-block;
          font-family: "Icon";
          content: "\e901";
          font-size: 18px;
          color: #7c7a7a;
          vertical-align: middle;
          font-weight: lighter;
        }
      }
      .dropdown_mobile_back_hide{
        margin: 2rem auto auto auto;
      }

      input {
        width: calc(100% - 35px);
        flex: 0 0 calc(100% - 35px);
        height: 100%;
        padding-right: 12px;
        padding-left: 12px;
        border-radius: var(--border-radius-sm);
        font-size: 14px;
        font-family: var(--font-bold);
        outline: 0;
        border: 2px solid #cdd3d9;
        &:focus {
          border-color: var(--primary-color-green);
        }
        &::placeholder {
          font-family: var(--font-regular);
        }
      }
      .app_dropdown_div
      {
        position: relative;
        display: flex;
        flex-direction: column;
        margin-top: 94px;
        width: 100%;
        input{
          width: auto;
          height: 46px;
          flex:none;
          margin-bottom: 2px;
          &:before {
            width:40px;
            height:40px;
            left:0;
            content: "";
            background-image: url('../../../public/static/images/home/location-trans.png');
            bottom:0;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
          }
        }
        span{
          position: absolute;
          margin-top: 14px;
          left: 5px;
          width: 20px !important;
          height: 20px !important;
        }
        .removeInput{
          position: absolute;
          right: 48px;
          margin-top: 14px;
          &::after{
            display: inline-block;
            font-family: "Icon";
            content: "";
            font-size: 6px;
            padding: 5px;
            border-radius: 50%;
            background-color: #565F65;
            color: #fff;
            font-weight: bolder;
          }
        }
        .app_input_pin{
          padding-left: 35px;
        }
        .detect_location{
          display: flex;
          justify-content: center;
          div{
              margin: auto 0;
          }
          p{
              padding: 25px 25px 25px 15px;
              font-size: 14px;
              color: #FDA000;
              font-family: var(--font-semi-bold);
              cursor: pointer;
          }
      } 
      &:after {
        display: block;
        width: 36px;
        border: 0.5px solid #9394A0;
        content: "";
        position: absolute;
        bottom: 0;
        left: 37%;
      }
      }
      .dropdown_mobile_input
      {
        margin-top: 66px;
      }
      .dropdown_hide_location
      {
        margin-top: 30px;
        &:after{
          bottom: -30%;
        }
      }
      
    }
    .location_results{
      margin-top: 100px;
      .location{
        padding: 15px 10px 10px;
        cursor: pointer;
        display: flex;
        &:hover, &:focus{
          background: #F0F0F0;
        }
        &:first{
          margin-top: 50px;
        }
        .location_text{
          margin-left: 16px;
          overflow: hidden;
          .small_add{
            font-size: 12px;
            font-family: var(--font-semi-bold);
            margin-bottom: 10px;
            color: #3D3D3D;
            text-align: initial;
          }
          .long_add{
            width: 320px;
            text-overflow: ellipsis;
            display: block;
            overflow: hidden;     
            white-space: nowrap;
            font-size: 14px;
            font-family: var(--font-regular);
            color: #565F65;
            text-align: initial;
          }
        }
      }
    }
  .error_block
    {
        background-color: rgba(208, 2, 27, 0.1);
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
        margin-top: 100px;
        .geo_error{
            font-size: 12px;
            line-height: 14px;
            color: #D0021B;
            text-align: initial;

        }
        p{
            line-height: 14px;
            margin-left: 10px;
            padding: 15px 0;
            cursor: pointer;
        }
        .err_img{
            margin: auto 0;
            margin-left: 20px;

        }
    }
  }
}

@media (min-width: map-get($grid-breakpoints,sm)) {
  .app_dropdown__mobile {
    .dropdown__menu_item_mobile {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 25px 32px !important;
    }
  }
  
}

.textfield__error {
  position: initial;
  bottom: -2px;
  left: 0;
  font-size: 10px;
  font-family: var(--font-medium);
  color: var(--font-color-error);
  transform: translateY(100%);
  @media (min-width: map-get($grid-breakpoints, md)) {
    font-size: 12px;
  }
}

.dropdown__disabled{
  background-color: #F8F8F3;
  opacity: 0.6;
}

.location_search {
  margin-top: 20px;
  margin-bottom: 5px;
  width: 90%;
  padding: 16px 37px 16px 16px;
  border-radius: 4px;
  font-size: 14px;
  letter-spacing: 0.23px;
  line-height: 1;
  font-family: var(--font-semi-bold);
  z-index: 5;
  padding-right: 45px;
  @media (min-width: map-get($grid-breakpoints,md)) {
    padding: 17px 30px 17px 16px;
    padding-right: 43px;
    transform: translateY(1px);
  }
  background: #FFFFFF;
  border: 1.5px solid var(--primary-color-green);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  &:focus {
    border-color: var(--primary-color-green);
    outline: none;
  }
}



.location{
  padding: 10px;
  width: 100%;
  cursor: pointer;
  display: flex;

  &:hover, &:focus{
    background: #F0F0F0;
  }
  .location_img{
    min-width: 24px;
    max-width: 24px;
    min-height: 24px;
    max-height: 24px;

  }

  .location_text{
    // {"textAlign":"initial","textOverflow":"ellipsis"}
    width: auto;
    margin-left: 16px;
    .small_add{
      font-size: 12px;
      font-family: var(--font-semi-bold);
      margin-bottom: 10px;
      color: #3D3D3D;
      text-align: initial;
    }
    .long_add{
      width: 250px;
      text-overflow: ellipsis;
      display: block;
      overflow: hidden;     
      white-space: nowrap;
      font-size: 14px;
      font-family: var(--font-regular);
      color: #565F65;
      text-align: initial;
  
    }
  }
}

.not_found, .not_found_device{
  margin-top: 6.25rem;
  @media (min-width: map-get($grid-breakpoints,md)) {
    margin-top: 20px;
  }
  font-family: var(--font-semi-bold);
  font-size: 16px;
  line-height: 19px;
  text-align: center;
}
.not_found_device{
  margin-top: 20px;
  
}

.another_loc{
  margin-top: 15px;
  font-family: var(--font-semi-bold);
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #565F65;
}

.hint_div{
  display: flex;
  justify-content: flex-start;
  margin-left: 16px;
  @media (min-width: map-get($grid-breakpoints,md)) {
    margin-top: 10px;
  }
  .hint_text{
    font-size: 12px;
    color: #aaa;
    font-family: var(--font-semi-bold);
    line-height: 16px;
    text-align: center;
    padding-left: 5px;
  }

  .hint_icon{
      display: inline-block;
      font-size: 0.75rem;
      width: 1rem;
      height: 1rem;
      line-height: 1rem;
      text-align: center;
      border-radius: 10px;
      background: #aaa;
      color: #fff;
      font-style: normal;
      font-weight: bold;
  }
}

.tracker_search{
  width: 100% !important;
}
.tracker_location{
  padding: 12px 20px;
  border-bottom: 0.5px solid var(--Outline, rgba(147, 148, 160, 0.25));
  &:last-child{
    border-bottom: none;
  }
}
.inputSearch{
  position: relative;
}

.locationTitle {
  display: flex;
  column-gap: 16px;
  margin-bottom: 12px;
  align-items: center;
  i {
    padding-top: 3px;
    cursor: pointer;
  }
  h5 {
    color: var(--CLP---Text-3, #3d3d3d);
    font-family: var(--font-semi-bold);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.tracker_input {
  max-width: 100% !important;
}

.tracker_location_icon {
  top: 52px !important;
}

.detect_location{
  display: flex;
  justify-content: center;
  div{
      margin: auto 0;
  }
  p{
      padding: 25px 25px 25px 15px;
      font-size: 14px;
      color: #FDA000;
      font-family: var(--font-semi-bold);
      cursor: pointer;
  }
} 
.app_dropdown__mobile_back{
  margin-right: 10px !important;
}